<template>
  <div class="wrapper-graph">
    <template v-if="renderGraph">
      <div class="contenCanvas">
        <canvas id="myChart4"/>
      </div>
    </template>
    <template v-else>
      <div class="no-results">
        <img src="/noResultados.svg">
      </div>
    </template>
  </div>
</template>

<script>
import AuthMixin from '../mixins/auth';

export default {
  name: 'BarGraph',
  mixins: [
    AuthMixin,
  ],
  props: {
    dataGrph: {
      type: Object,
      default: null,
    },
    textVert: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      renderGraph: false,
    };
  },
  mounted() {
    if (Object.entries(this.dataGrph).length > 0) {
      this.renderGraph = false;
      if (this.dataGrph.cronico.length > 0) this.renderGraph = true;
      const self = this;
      setTimeout(() => {
        self.dataGraph();
      }, 600);
    } else {
      this.renderGraph = false;
    }
  },
  methods: {
    dataGraph() {
      let objRotation = {};
      let dataGet = this.dataGrph;
      if (this.textVert) {
        objRotation = {
          maxRotation: 80,
          minRotation: 80,
        };
      }
      dataGet.cronico.push(100);
      const ctx = document.getElementById('myChart4').getContext('2d');
      const myChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: dataGet.axisX,
          datasets: [
            {
              label: 'Ausentismo crónico',
              backgroundColor: '#FF5454',
              data: dataGet.cronico,
            }, {
              label: 'Riesgo ausentismo crónico',
              backgroundColor: '#FFCF54',
              data: dataGet.riesgo,
            },
          ],
        },
        options: {
          tooltips: {
            // enabled: false,
            displayColors: true,
            callbacks: {
              mode: 'x',
            },
          },
          scales: {
            xAxes: [{
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: objRotation,
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                beginAtZero: true,
              },
              type: 'linear',
            }],
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            position: 'top',
            labels: {
              usePointStyle: true,
            },
          },
          hover: {
            animationDuration: 1,
          },
          animation: { // Este fragmento genero los numeros sobre las barras de la grafica
            duration: 1,
            onComplete() {
              const chartInstance = this.chart;
              const ctx = chartInstance.ctx;
              ctx.textAlign = 'center';
              ctx.fillStyle = '#000';
              ctx.textBaseline = 'middle';
              ctx.font = 'bold 12px Helvetica, Arial, sans-serif';
              const arrayBarBottom = [];
              let numFor = 0;
              let totalizar = false;

              // Loop through each data in the datasets

              this.data.datasets.forEach((dataset, i) => {
                numFor += 1;
                let barShow = false;
                let valoresMeta = Object.values(dataset._meta);
                for (let i=0; i< valoresMeta.length; i++) {
                  if (!barShow && valoresMeta[i].hidden === null) {
                    barShow = true;
                  }
                }
                const meta = chartInstance.controller.getDatasetMeta(i);
                if (barShow) {
                  meta.data.forEach((bar, index) => {
                    const data = dataset.data[index];
                    const centerY = bar._model.y + ((bar._model.base - bar._model.y) / 2);
                    ctx.fillText(`${data}%`, bar._model.x, centerY);
                    if (numFor === 1) {
                      arrayBarBottom.push(data);
                      totalizar = true;
                    }
                    if (totalizar && numFor === 2) {
                      const totalData = data + arrayBarBottom[index];
                      ctx.fillText(`${totalData}%`, bar._model.x, bar._model.y - 7);
                    } else {
                      ctx.fillText('', bar._model.x, bar._model.y - 7);
                    }
                  });
                }
              });
            },
          },
        },
      });
    },
  },
};
</script>

<style>
  .wrapper-graph {
    min-height: 330px;
  }
  .no-results {
    text-align: center;
  }
  .contenCanvas {
    max-height: 500px;
    min-height: 300px;
  }
</style>
