var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.newStyle)?_c('div',_vm._l((_vm.tabs),function(tab,index){return _c('button',{key:index,class:[
      'btn-verticalmenu',
      'rdr-btn--menuconfig',
      { 'btn-active': _vm.value === tab.key } ],on:{"click":function($event){$event.preventDefault();return _vm.$emit('input', tab.key)}}},[_vm._v("\n    "+_vm._s(tab.text)+"\n  ")])}),0):_c('div',{class:_vm.$style.tabs},[(_vm.currentTab)?_c('div',{class:[_vm.$style.currentTab, 'print-only']},[_c('span',[_vm._v(_vm._s(_vm.links ? _vm.currentTab.name : _vm.currentTab.text))])]):_vm._e(),_vm._v(" "),_c('div',{class:[_vm.$style.tabsOuterWrapper, 'hide-print']},[(_vm.tabs.length > 0)?_c('div',{class:[_vm.$style.leftBorder, 'hide-print']},[_c('span')]):_vm._e(),_vm._v(" "),(_vm.links)?_c('div',{class:[_vm.$style.tabsWrapper]},_vm._l((_vm.tabs),function(tab){
    var _obj;
return _c('router-link',{key:tab.name,class:[_vm.$style.tab, 'hide-print', ( _obj = {}, _obj[_vm.$style.active] = _vm.match(tab.link.name), _obj )],style:(_vm.tabStyle),attrs:{"to":tab.link}},[_c('i',{class:[_vm.$style.materialIcons, 'material-icons']},[_vm._v(_vm._s(tab.icon))]),_vm._v(" "),_c('div',[_vm._v(" "+_vm._s(tab.name))])])}),1):_c('div',{class:[_vm.$style.tabsWrapper]},_vm._l((_vm.tabs),function(tab,index){
    var _obj;
return _c('a',{key:index,class:[
            _vm.$style.tab,
            'hide-print',
            ( _obj = {}, _obj[_vm.$style.active] = _vm.value === tab.key, _obj ) ],attrs:{"title":tab.text},on:{"click":function($event){$event.preventDefault();return _vm.$emit('input', tab.key)}}},[_c('i',{class:[_vm.$style.materialIcons, 'material-icons']},[_vm._v(_vm._s(tab.icon))]),_vm._v(" "),_c('div',{staticStyle:{"overflow":"hidden"}},[_vm._v(_vm._s(tab.text))])])}),0),_vm._v(" "),(_vm.tabs.length > 0)?_c('div',{class:[_vm.$style.rightBorder, 'hide-print']},[_c('span')]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }