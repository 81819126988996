var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"report"},[(_vm.routeMatches('gradesGroupAverageBySchool'))?[_c('alert-info',{attrs:{"text-alert":_vm.textInfo,"icon-viwe":false}})]:_vm._e(),_vm._v(" "),(_vm.routeMatches('gradesGroupAverageBySchool'))?[_c('alert-info',{attrs:{"text-alert":_vm.textInfo2,"icon-viwe":false}})]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"rdr-view grades-view"},[_c('rdr-results-header',{attrs:{"tabs":[
        {
          name: 'Promedios de cursos',
          link: _vm.gradesGroupAverageBySchoolLink
        },
        {
          name: 'Cantidad de calificaciones',
          link: _vm.gradesTestCountBySchoolLink
        } ],"show-school-selector":_vm.isLoggedIn,"is-months":false,"year-picker":"","month-picker":"","hide-school-picker":"","show-school-picker":"","show-school-year-picker":"","show-planned-term-picker":""}},[_c('br'),_vm._v(" "),_c('span',{staticClass:"results-header__subtitle"},[_vm._v("\n        Última actualización: "+_vm._s(_vm.lastUpdated || '')+"\n      ")])]),_vm._v(" "),_c('router-view')],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }