var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"custom-class":_vm.$style.subjectDialog,"visible":_vm.show,"append-to-body":""},on:{"update:visible":function($event){_vm.show=$event}}},[(_vm.school)?_c('h3',[_vm._v(_vm._s(_vm._f("startCase")(_vm.school.name)))]):_vm._e(),_vm._v(" "),(_vm.group && _vm.year)?_c('h3',[_vm._v(_vm._s(_vm.group.name)+" — "+_vm._s(_vm.year))]):_vm._e(),_vm._v(" "),(_vm.subject)?_c('h3',[_vm._v(_vm._s(_vm.subject.label))]):_vm._e(),_vm._v(" "),(_vm.test)?_c('h3',[_vm._v(_vm._s(_vm.test.label)+" — "+_vm._s(_vm.test.testName))]):_vm._e(),_vm._v(" "),_c('histogram',{attrs:{"data":_vm.data,"options":{
      legend: {
        position: 'none',
      },
      hAxis: {
        title: 'Rango de calificación',
      },
      vAxis: {
        title: 'Cantidad de estudiantes',
      },
      histogram: {
        minValue: 1,
        maxValue: 7,
        bucketSize: 1
      },
  }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }