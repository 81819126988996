<template>
  <div>
    <i
      class="clg-filter conten-icon-filter"
      @click="showFilters = !showFilters"/>
    <div
      :class="$style.container">
      <div :class="$style.filters">
        <el-dialog
          :class="$style.filtersDialog"
          :visible.sync="showFilters"
          append-to-body
          center>
          <div
            slot="title"
            :class="$style.filterTitle">
            <i class="clg-filter"/> Filtros
            <div :class="$style.filterTitleColored">
              Estudiantes: {{ countResponse || 0 }}
            </div>
          </div>
          <div :class="$style.contenForm">
            <filters
              :active-filters.sync="activeFilters"
              :filters="filters"
              :current-page="currentPage"
              :show-filters="showFilters"
              :all-filters="allFilters"
              :loading="loading"
              :background="false"
              button
              margin="0px"
              orientation="horizontal"
              @apply-filters="applyFilters"
              @reset-filters="resetFilters"/>
          </div>
        </el-dialog>
      </div>
      <div
        :class="[
          $style.tableWithFilters,
      ]">
        <div
          :class="[
            'button-wrapper',
            'hide-print',
            $style.filterButtons,
        ]">
          <!-- <button
            :class="[
              'rdr-btn',
              'rdr-btn--small',
              'rdr-btn--primary',
              'visibility-button',
            ]"
            @click="showFilters = !showFilters">
            <span>Mostrar filtros</span>
          </button> -->
          <button
            v-for="(values, key) in activeTrueFilters"
            :class="[
              'rdr-btn',
              'rdr-btn--small',
              'visibility-button',
            ]"
            :key="key"
            @click="resetFilter(key)">
            <span> {{ getFilterLabel(key) }} </span>
            <i class="material-icons">remove_circle</i>
          </button>
          <button
            v-if="Object.values(activeFilters).some(filter => filter == true)"
            :class="[
              'rdr-btn',
              'rdr-btn--small',
              'visibility-button',
            ]"
            @click="resetFilters">
            <span>Resetear filtros</span>
          </button>
        </div>
        <div :class="$style.filterTitleColored">
          Estudiantes: {{ countResponse || 0 }}
        </div>
      </div>
    </div>
    <div
      v-if="rows"
      :class="$style.divSearch">
      <input
        v-model="searchQuery"
        :class="$style.inpSearch"
        type="text"
        placeholder="Buscar por nombre de estudiante"
      >
      <i
        class="clg-search"
        :class="$style.iconSearch"
      ></i>
    </div>
    <transition name="fade">
      <loading-overlay v-if="loading"/>
    </transition>
    <strong v-if="error">Ocurrió un error. Intenta de nuevo</strong>
    <div
      v-else-if="rows && !loading"
      :class="$style.table"
    >
      <div class="contenScroll">
        <rdr-table
          :columns="columns"
          :data="rows"
          :custom-class="$style.courseReportTable"
          :click="handleClick"
          :extended-header-class="$style.courseReportHeaderCell"
          :extended-cell-class="$style.courseReportCell"
          :sticky-top-offset="stickyTopOffset"
          :hidden-columns-count="hiddenColumnsCount"
          show-row-index
        />
      </div>
    </div>
    <div
      v-if="rows.length > 0"
      class="contenPaginacion"
    >
      <div class="divNumRow">
        Mostrar:
        <select
          :class="$style.rdrPickerSelect"
          style="padding:4px;border-radius:7px;"
          @change="changeRows($event.target.value)">
          <option
            v-for="option in optionsRows"
            :value="option">
            {{ option }}
          </option>
        </select> de {{ countResponse }} fichas
      </div>
      <div
        v-if="showPaginator"
        class="divPaginator">
        <el-pagination
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :page-count="totalPages"
          :class="[
            $style.pagination,
            $style.centerItem,
          ]"
          layout="prev, pager, next"
          background/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { Dialog, Pagination } from 'element-ui';
import debounce from 'lodash/debounce';
import SchoolsApi from '../../api/schools';
import StudentsApi from '../../api/students';
import DataBox from '../../components/utils/data-box';
import ProgressBar from '../../components/base/progress-bar';
import AuthMixin from '../../mixins/auth';
import StageGroupsMixin from '../../mixins/stage-groups';
import PermissionMixin from '../../mixins/permission';
import Decree67Mixin from '../../mixins/decree-67';
import TooltipInfoMixin from '../../mixins/tooltip-info';
import Filters from '../../components/filters.vue';
import { MONTHS } from '../../utils/months';

export default {
  name: 'AttendanceByStudentTableView',
  components: {
    'el-dialog': Dialog,
    'el-pagination': Pagination,
    DataBox,
    ProgressBar,
    Filters,
  },
  mixins: [AuthMixin, PermissionMixin, StageGroupsMixin, Decree67Mixin, TooltipInfoMixin],
  props: {
    stickyTopOffset: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      activeFilters: {},
      error: false,
      showFilters: false,
      summaryInfo: {},
      students: [],
      schoolGroups: [],
      countResponse: 0,
      totalPages: 0,
      colors: ['#ffffff', '#e06666'],
      baseFilters: {
        'Curso': [
          {
            label: 'Curso',
            dropdownPlaceholder: 'Elige los cursos',
            exclusionClass: 'group',
            type: 'dropdown',
            key: 'group_ids',
            options: [],
            selectedGroups: [],
            loading: false,
          },
        ],
        'Ciclo': [
          {
            label: 'Ciclo',
            dropdownPlaceholder: 'Selecciona un ciclo',
            exclussionClass: 'stage_group',
            type: 'dropdownMultiple',
            key: 'stage_group_ids',
            options: [],
            selectedGroups: [],
            loading: false,
          },
        ],
        'Características del estudiante': [
          {
            label: 'Preferente',
            filterFunc: () => row => row.preferential,
            exclusionClass: 'preferentialOrPriority',
            type: 'switch',
            key: 'preferential',
          },
          {
            label: 'Prioritario',
            filterFunc: () => row => row.priority,
            exclusionClass: 'preferentialOrPriority',
            type: 'switch',
            key: 'priority',
          },
          // {
          //   label: 'PIE',
          //   filterFunc: () => row => row.pieBoolean,
          //   exclusionClass: 'pie',
          //   type: 'switch',
          //   key: 'pie',
          //   mainItem: true,
          // },
          {
            label: 'Permanente',
            filterFunc: () => row => row.permanentPie,
            exclusionClass: 'pie',
            type: 'switch',
            key: 'permanent_pie',
            subitem: true,
          },
          {
            label: 'Transitorio',
            filterFunc: () => row => row.temporaryPie,
            exclusionClass: 'pie',
            type: 'switch',
            key: 'temporary_pie',
            subitem: true,
          },
        ],
      },
      repetitionFilter: {
        'Repitencia': [
          {
            label: 'Repitentes',
            filterFunc: () => row => row.repetition,
            exclusionClass: 'repetition',
            type: 'switch',
            key: 'repetition',
            subitem: true,
          },
        ],
      },
      attendanceFilters: {
        'Asistencia': [
          {
            label: 'Ausentismo crónico',
            exclusionClass: 'attendance',
            type: 'switch',
            key: 'chronic_absenteeism',
          },
        ],
      },
      optionsRows: [10, 20, 30, 40],
      pageSize: 10,
      showPaginator: true,
    };
  },
  computed: {
    ...mapState('results', ['loading']),
    ...mapState('options', ['chosenYear', 'chosenSchoolId']),
    allFilters() {
      return [].concat(...Object.values(this.filters));
    },
    filters() {
      return {
        ...this.baseFilters,
        ...this.repetitionOrRepetitionRiskFilters,
        ...this.attendanceFilters,
      };
    },
    repetitionOrRepetitionRiskFilters() {
      if (this.hasRepetitionValues) return this.repetitionFilter;
      if (this.hasRepetitionRiskValues) return this.repetitionRiskFilter;
      return {};
    },
    repetitionRiskFilter() {
      return {
        [this.repetitionRiskLabel]: [
          {
            label: 'General',
            filterFunc: () => row => row.repetitionRisk,
            exclusionClass: 'repetitionRisk',
            type: 'switch',
            key: 'repetition_risk',
          },
          {
            label: 'Por calificaciones',
            filterFunc: () => row => row.repetitionRiskGrades,
            exclusionClass: 'repetitionRisk',
            type: 'switch',
            key: 'repetition_risk_grades',
            subitem: true,
          },
          {
            label: 'Por asistencia',
            filterFunc: () => row => row.repetitionRiskAbsenteeism,
            exclusionClass: 'repetitionRisk',
            type: 'switch',
            key: 'repetition_risk_absenteeism',
            subitem: true,
          },
        ],
      };
    },
    currentPage: {
      get() {
        return parseInt(this.$route.query.page, 10) || 1;
      },
      set(value) {
        this.$router.replace(
          { ...this.$route, query: { ...this.$route.query, page: value } },
        );
      },
    },
    searchQuery: {
      get() {
        return this.$route.query.search || '';
      },
      set(value) {
        if (value.length === 0 || value.length > 2) {
          this.$router.replace({ ...this.$route, query: { ...this.$route.query, search: value } });
        }
      },
    },
    options() {
      return {
        school: this.chosenSchoolId, // eslint-disable-line camelcase
        year: this.chosenYear,
        page: this.currentPage,
        retired: false,
      };
    },
    columns() {
      const tableColumns = [
        { key: 'group_short_name', label: 'Curso', textAlign: 'left', sortFunc: (a, b) => a.localeCompare(b), sticky: true },
        { key: 'full_name', label: 'Estudiante', textAlign: 'left', minWidth: 150, sortFunc: (a, b) => a.localeCompare(b), stickyTwo: true },
        { key: 'previousYearAbsenteeismValue', label: `Ausentismo ${this.options.year - 1}`, format: 'percentage', type: 'data-box', sortFunc: (a, b) => a - b, colors: this.colors, minValue: 3, maxValue: 10, norm: 'previousYearAbsenteeismValue' },
        { key: 'cumulativeAbsenteeismValue', label: `Ausentismo ${this.options.year}`, format: 'percentage', type: 'data-box', sortFunc: (a, b) => a - b, colors: this.colors, minValue: 3, maxValue: 10, norm: 'cumulativeAbsenteeismValue' },
        ...this.monthsColumns,
      ];

      if (this.activeStudents.some(e => e.monthly_absenteeism.length)) {
        tableColumns.push({ key: 'daysAbsentTotal', label: 'Inasistencias Totales', sortFunc: (a, b) => a - b });
      }

      return tableColumns;
    },
    rows() {
      return this.activeStudents
        .slice()
        .map(e => {
          const attributes = {
            ...e,
            repetitionRiskBoolean: !!e.repetition_risk,
            chronicAbsenteeism: e.chronic_absenteeism === null ? false : e.chronic_absenteeism,
            finalGradeValue: e.final_grade.value,
            attendanceValue: e.attendance.value,
            tooltip: this.studentData(e),
            preferential: e.preferential,
            priority: e.priority,
            temporaryPie: e.pie_type === 'Transitorio',
            permanentPie: e.pie_type === 'Permanente',
            pieBoolean: e.pie_boolean === true,
            repetitionRisk: e.repetition_risk,
            repetitionRiskAbsenteeism: e.repetition_risk === 3, // eslint-disable-line no-magic-numbers
            repetitionRiskGrades: [1, 2, 4, 5].includes(e.repetition_risk), // eslint-disable-line no-magic-numbers
            repetition: e.repetition,
            previousYearAbsenteeismValue: e.previous_year_absenteeism.value,
            attendanceNormValue: e.attendance.norm_value,
            cumulativeAbsenteeismValue: e.cumulative_absenteeism.value,
          };
          this.addMonthlyAbsenteeismTo(e.monthly_absenteeism, attributes);

          return attributes;
        });
    },
    monthsColumns() {
      const months = new Set();
      this.activeStudents.forEach(student => {
        student.monthly_absenteeism.forEach(monthlyAbsenteeism => {
          months.add(monthlyAbsenteeism.month);
        });
      });

      return [...months].sort((a, b) => a - b).map(month => (
        {
          key: `daysAbsent${MONTHS[month - 1]}`,
          label: `Inasistencias ${MONTHS[month - 1]}`,
          sortFunc: (a, b) => a - b,
        }
      ));
    },
    activeStudents() {
      return this.students.filter(s => !s.retired);
    },
    hasRepetitionValues() {
      return this.students.some(e => typeof e.repetition === 'boolean');
    },
    hasRepetitionRiskValues() {
      return this.students.some(e => typeof e.repetition_risk === 'number');
    },
    hiddenColumnsCount() {
      return this.monthsColumns.length ? this.monthsColumns.length + 1 : 0;
    },
    activeTrueFilters() {
      return Object.entries(this.activeFilters).reduce((newObj, [key, val]) => {
        if (val === true) {
          newObj[key] = val;
        }
        return newObj;
      }, {});
    },
    queryFilters() {
      return Object.keys(this.activeFilters).filter(
        key => this.activeFilters[key] === true
      ).reduce((o, key) => {
        switch (key) {
        case 'group_ids':
          return { ...o, [key]: this.filters.Curso[0].selectedGroups.join() };
        case 'stage_group_ids':
          return {
            ...o,
            [key]: this.filters.Ciclo[0].options
              .filter(x => this.filters.Ciclo[0].selectedGroups.includes(x.key))
              .map(stage => stage.groups)
              .flat()
              .map(group => group.id)
              .join(),
          };
        default:
          return { ...o, [key]: true };
        }
      }, {});
    },
  },
  watch: {
    options: {
      handler(newValue, oldValue) {
        if (newValue && oldValue && newValue.school === oldValue.school) {
          this.reloadStudents();
        } else {
          this.fetchInfo();
        }
      },
      immediate: true,
    },
    queryFilters: {
      handler() {
        this.fetchStudentsCount();
      },
      immediate: true,
    },
    searchQuery(newValue) {
      if (newValue.length === 0 || newValue.length > 3) { // eslint-disable-line no-magic-numbers
        this.debouncedFetchStudents();
      }
    },
  },
  async created() {
    await this.fetchGroups();
  },
  methods: {
    ...mapMutations('results', {
      addLoading: 'ADD_LOADING',
      removeLoading: 'REMOVE_LOADING',
    }),
    async fetchInfo() {
      this.activeFilters = this.getActiveFilters(this.$route.query);
      if ('group_ids' in this.activeFilters) {
        this.baseFilters.Curso[0].selectedGroups = this.$route.query.group_ids.split(',').map(x => +x);
      }
      if ('stage_group_ids' in this.activeFilters) {
        this.baseFilters.Ciclo[0].selectedGroups = this.$route.query.stage_group_ids.split(',');
      }
      await Promise.all([
        this.fetchStudents(),
        this.fetchGroups(),
        this.fetchStudentsCount(),
      ]);
    },
    async fetchGroups() {
      this.addLoading();
      try {
        const { body: { groups } } = await SchoolsApi.getSchoolCourses(
          this.chosenSchoolId,
          { courses_only: true, year: this.chosenYear } // eslint-disable-line camelcase
        );
        this.schoolGroups = groups;
        this.filters.Curso[0].options = this.schoolGroups;
        this.filters.Ciclo[0].options = this.stageGroups.slice(1);
      } catch (_) {
        this.error = true;
      } finally {
        this.removeLoading();
      }
    },
    debouncedFetchStudents: debounce(function () {
      this.fetchStudents();
    }, 1000), // eslint-disable-line no-magic-numbers
    async fetchStudents() {
      this.addLoading();
      this.error = false;
      try {
        const response = await StudentsApi.searchStudents(
          {
            ...this.options,
            attendance_and_final_grade: true, // eslint-disable-line camelcase
            ...this.queryFilters,
            search: this.searchQuery,
          },
          this.currentPage,
          this.pageSize
        );

        this.students = response.body.evaluable_students; // eslint-disable-line camelcase
        this.totalPages = response.body.meta.total_pages;
        this.showPaginator = true;
      } catch (_) {
        this.error = true;
        this.pageSize = 10;
      } finally {
        this.removeLoading();
      }
    },
    async fetchStudentsCount() {
      StudentsApi.countStudents({
        ...this.options,
        ...this.queryFilters,
        search: this.searchQuery,
      }).then(({ body: { evaluable_students_count } }) => { // eslint-disable-line camelcase
        this.countResponse = evaluable_students_count; // eslint-disable-line camelcase
      }).catch(() => { this.error = false; });
    },
    async reloadStudents() {
      await this.fetchStudents();
      await this.fetchStudentsCount();
    },
    getFilterLabel(filterKey) {
      const filter = this.allFilters.find(obj => obj.key === filterKey);

      return filter && filter.label;
    },
    addMonthlyAbsenteeismTo(data, attributes) {
      let total = 0;
      data.forEach(res => {
        const key = `daysAbsent${MONTHS[res.month - 1]}`;
        attributes[key] = res.value;
        total += res.value;
      });

      attributes.daysAbsentTotal = data.length ? total : '—';
    },
    applyFilters() {
      this.showFilters = false;
    },
    resetFilter(filterKey) {
      if (filterKey === 'group_ids') {
        this.filters.Curso[0].selectedGroups = [];
      } else if (filterKey === 'stage_group_ids') {
        this.filters.Ciclo[0].selectedGroups = [];
      }

      const filtered = Object.keys(this.activeFilters)
        .filter(key => ![filterKey].includes(key))
        .reduce((obj, key) => {
          obj[key] = this.activeFilters[key];
          return obj;
        }, {});
      this.activeFilters = filtered;
      this.debouncedFetchStudents();
    },
    resetFilters() {
      this.showFilters = false;
      this.activeFilters = {};
      this.baseFilters.Curso[0].selectedGroups = [];
      this.baseFilters.Ciclo[0].selectedGroups = [];
      this.fetchStudents();
    },
    handleClick({ id }) {
      this.$router.push({
        name: 'studentReport',
        params: { student: id },
      });
    },
    getActiveFilters(filterObject) {
      return Object.keys(filterObject)
        .reduce(
          (a, b) => ({ ...a, ...(b === 'page' ? {} : { [b]: true }) }),
          {}
        );
    },
    changeRows(data) {
      this.showPaginator = false;
      this.pageSize = parseInt(data);
      this.fetchStudents();
    },
  },
};
</script>

<style lang="scss" module>
@import "../../../styles/app/variables";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-weight: bold;
  font-size: 22px;
}

.sub-title {
  margin-top: 5px;
  margin-bottom: 15px;
}

.table {
  font-weight: 200;
  padding: 0 30px;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 28px;
    font-weight: 200;
    padding: 0 30px;
    margin: 10px 0;
  }

   &__subtitle {
      font-size: 18px;
      margin-bottom: 30px;
   }
}
.value {
  width: 15%;
  text-align: center;
}

.bar {
  flex-grow: 100;
  align-items: center;
}

table.course-report-table {
  min-width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-weight: 200;

  thead {
    th {
      position: sticky;
      top: 0;
      z-index: 300;
      background-color: $sidebar-background;
    }
  }

  tbody {
    tr {
      td {
        border-bottom: 1px solid $sidebar-background;
      }
    }
    tr:hover {
      td {
        background-color: #f9f9fd;
      }
    }
  }
}

div.course-report-header-cell {
  height: 42px;

  span {
    flex: 1;
  }
}

div.course-report-cell {
  font-size: 14px;
  height: 40px;
}

.filters {
  width: 100%;
  display: flex;
  align-items: start;
  font-size: 13px;

  &-dialog {
    :global(.el-dialog) {
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      // width: 60%;
      width: 40%;
      margin-left: 60%;
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      height: 100%;

      &__title {
        font-size: 20px;
        font-weight: bold;
        white-space: normal;
        overflow: hidden;
        color: #177ED3;
      }

      @media only screen and (max-width: $mobile-breakpoint) {
        width: 95%;
      }
    }

    :global(.el-dialog__header) {
      margin-top: 10px;
      text-align: left;
    }
  }

  @media only print {
     flex-direction: column;
  }
}

.table-with-filters {
  width: 100%;
}

.center-item {
  padding-top: 10px;
  // display: flex;
  justify-content: right;
}

.filter-buttons {
  padding-bottom: 15px;

  button {
    margin: 0px 2px;
  }
}

.filter-title {
  font-size: 20px;
  font-weight: 600;
  color: #177ED3;
}

.filter-title-colored {
  color: $primary-color;
  font-weight: 700;
  padding: 5px 10px;
}

.pagination {
  text-align: right;
  margin-top: 40px;

  &.el-pagination.is-background .el-pager li:not(.disabled){
    &.active {
      background-color: $primary-color;

      &:hover {
        color: #fff;
      }
    }

    &:hover {
      color: $primary-color;
    }
  }
}

.loading {
  margin-top: 30px;
}

.conten-form {
  overflow:auto;
  padding-top: 10px;
  height: 100%;
  padding: 20px 0px;
}
.div-search {
  text-align: right;
  margin-top: -38px;
  margin-bottom: 20px;
}
.inp-search {
  border-radius: 10px;
  height: 42px;
  padding: 0 32px 0 16px;
  width: 320px;
  border: 1px solid #ccc;
}
.icon-search {
  margin-left: -34px;
  padding-right: 8px;
  color: #888;
  margin-right: 30px;
}
</style>
