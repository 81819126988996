<template>
  <div class="conten-frm">
    <template v-if="loadView">
      <div class="title-sesion">
        Iniciar sesión
      </div>
      <form
        class="login-dialog__form"
        @submit.prevent="login">
        <div
          v-if="unauthorized"
          class="login-dialog__error">
          <span>El correo y/o la contraseña no son correctas. <br>Por favor verifique sus datos.</span>
        </div>
        <div
          v-else-if="resend"
          class="login-dialog__error">
          <span>Aun no haz confirmado tu registro en radar. <br>Te enviamos nuevamente un correo para activar tu cuenta <br>(si no lo encuentras, revisa en tu carpeta de spam).</span>
        </div>
        <div
          v-else-if="forbidden"
          class="login-dialog__error">
          <span>Aun no haz confirmado tu registro en radar. <br> Revisa el correo que te enviamos<br>(Si no lo encuentras, revisa en tu carpeta de spam).</span>
        </div>
        <input
          v-model="email"
          class="login-dialog__input rdr-input rdr-input--block"
          type="email"
          placeholder="Usuario"
          required
          autofocus
          @keyup.down="cleanError">
        <input
          v-model="password"
          class="login-dialog__input rdr-input rdr-input--block"
          type="password"
          placeholder="Contraseña"
          required>
        <div class="password-recover">
          <a
            href="#"
            @click="toggleForPass">
            ¿Olvidaste tu contraseña?
          </a>
        </div>
        <button
          :disabled="submitting"
          class="rdr-btn rdr-btn--block rdr-btn--large rdr-btn--primary"
          type="submit">
          <span v-if="!submitting">Iniciar sesión</span>
          <span v-if="submitting">Entrando...</span>
        </button>

        <hr class="dividerLogin">
        <div class="contenO">ó</div>
        <hr class="dividerLogin">

        <div class="google">
          <div class="google__btn">
            <a href="/users/auth/google_oauth2">
              <img
                class="google__logo"
                src="/google-logo.svg">
              <div class="google__text-container">
                <span class="google__login-text">
                  Iniciar sesión con Google
                </span>
              </div>
            </a>
          </div>
        </div>
      </form>

      <!-- <div class="google">
        <div class="google__btn">
          <router-link
            :to="{ name: 'proQuote' }"
          >
            <div class="google__text-container-full">
              <span class="google__login-text-full">
                Cotizar
              </span>
            </div>
          </router-link>
        </div>
      </div> -->

      <div class="google" @click="showMdlQuote">
        <div class="google__btn">
            <div class="google__text-container-full">
              <span class="google__login-text-full">
                Cotizar
              </span>
            </div>
        </div>
      </div>

      <div class="free-trial">
        <a
          href="#"
          @click="showFreeTrial" >
          Probar gratis con datos públicos
        </a>
      </div>

      <el-dialog
        v-if="freeTrial"
        :visible.sync="freeTrial"
        class="radar-dialog login-dialog">
        <div
          slot="title">
          <h2 style="text-align: left;">Probar gratis</h2>
        </div>
        <form
          class="login-dialog__form"
          @submit.prevent="">
          <div class="school-form">
            <school-select
              v-model="chosenSchool"
              class="school-form__select" />
            <div class="school-form__lead">Analiza <strong>gratis</strong> los datos públicos de tu colegio.</div>
          </div>
        </form>
      </el-dialog>

      <el-dialog
        :visible.sync="mdlPass"
        class="radar-dialog login-dialog">
        <div
          slot="title"
          class="radar-dialog__title">
          Recuperar contraseña
        </div>
        <form
          class="login-dialog__form"
          @submit.prevent="recoverPassword">
          <div
            v-show="unauthorized"
            class="login-dialog__error">
            <span>
              No existe un usuario registrado con ese correo.<br>
              Por favor, verifica tus datos.
            </span>
          </div>
          <div
            v-show="success"
            class="login-dialog__success">
            <span>
              Se ha enviado un mensaje a tu correo.<br>
              Verifica las instrucciones de recuperación.
            </span>
          </div>
          <input
            v-show="!success"
            v-model="email"
            class="login-dialog__input rdr-input rdr-input--block"
            type="email"
            placeholder="Correo electrónico"
            required
            autofocus
            @keyup.down="cleanError">
          <button
            v-show="!success"
            :disabled="submitting"
            class="rdr-btn rdr-btn--block rdr-btn--large rdr-btn--primary"
            type="submit">
            <span v-if="!submitting">Recuperar contraseña</span>
            <span v-if="submitting">Enviando...</span>
          </button>
          <!-- <div class="google">
            <button
              class="rdr-btn rdr-btn--block rdr-btn--small"
              @click.prevent="toggleForPass">
              Volver
            </button>
          </div> -->
        </form>
      </el-dialog>

      <!-- ===== MODAL COTIZAR ===== -->
      <el-dialog
        v-if="mdlQuote"
        :visible.sync="mdlQuote"
        class="radar-dialog login-dialog">
        <div
          slot="title">
          <h2 class="titleh2">Cotizar servicio</h2>
        </div>
        <div class="mdl-conten-body">
          <h3>
            Escríbenos a <a href="mailto:radar@colegium.com">radar@colegium.com</a>
          </h3>
          <p class="conten-text">
                Pide tu cotización escribiendo a nuestro correo indicando el nombre y RBD
                del(los) colegio(s) para los que quieres cotizar y los servicios en los que estás interesado(a).
          </p>

          <hr class="dividerLogin">
          <div class="contenO">ó</div>
          <hr class="dividerLogin">
          <rdr-contact-notification />
          <contact-form-dialog />

        </div>
      </el-dialog>

    </template>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import { Dialog, Notification } from 'element-ui';

import LinksMixin from '../../mixins/links';
import SchoolSelect from '../../components/school-select';
import ContactNotification from '../../components/partials/contact-notification.vue';
import ContactFormDialog from '../../components/dialogs/contact-form.vue';



export default {
  name: 'Login',
  components: {
    'el-dialog': Dialog,
    'school-select': SchoolSelect,
    'rdr-contact-notification': ContactNotification,
    ContactFormDialog,
  },
  mixins: [LinksMixin],

  props: {
    loadView: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      freeTrial: false,
      mdlPass: false,
      loginForm: true,
      success: false,
      email: '',
      password: '',
      chosenSchool: null,
      mdlQuote: false,
    };
  },
  computed: {
    ...mapState('ui', ['loading']),
    submitting() { return !!this.$store.state.ui.loading; },
    error() { return this.$store.state.auth.authError; },
    unauthorized() { return this.error && this.$store.state.auth.authErrorCode === 401; }, // eslint-disable-line no-magic-numbers
    forbidden() { return this.error && this.$store.state.auth.authErrorCode === 403; }, // eslint-disable-line no-magic-numbers
    resend() { return this.forbidden && this.$store.state.auth.authErrorBody.resend; },
    show: {
      get() { return this.$store.state.ui.dialogs.login.open; },
      set(value) { this.$store.commit({ type: 'ui/SET_DIALOG_STATE', dialog: 'login', state: value }); },
    },
  },
  watch: {
    async chosenSchool(newSchool) {
      if (newSchool) {
        try {
          this.addLoading();
          this.closeDialog();
          await this.setChosenSchool(newSchool.id);
          this.$router.push(this.landingLink);
        } catch (_) {
          this.error = true;
        } finally {
          this.removeLoading();
        }
      }
    },
  },

  mounted() {
    window.location.hash="no-back-button";
    window.location.hash="Again-No-back-button";//esta linea es necesaria para chrome
    window.onhashchange=function(){
      window.location.hash="no-back-button";
    }
  },

  methods: {
    cleanError() {
      this.$store.commit('auth/cleanError');
    },
    showFreeTrial() {
      this.freeTrial = !this.freeTrial;
    },
    showMdlQuote() {
      this.mdlQuote = !this.mdlQuote;
    },
    /* eslint-disable max-statements */
    async login() {
      const creds = {
        email: this.email,
        password: this.password,
      };
      this.$store.commit('ui/ADD_LOADING');
      try {
        await this.$store.dispatch('auth/login', creds);
        this.$store.dispatch('ui/hideDialog', 'login');
        Notification.closeAll();
        this.$store.commit('ui/hideMobileMenu');
        if (['/', '/login', '/aptus'].includes(this.$route.path)) {
          this.$store.commit('ui/ADD_LOADING');
          this.$router.push(
            this.landingLink,
            () => {
              this.$store.commit('ui/REMOVE_LOADING');
              this.$freshchat.showWidget();
              this.$freshchat.setUserProperties({ email: this.userEmail });
            },
          );
        } else {
          sessionStorage.setItem('useOwnSchools', true);
          location.reload();
        }
      } finally {
        this.$store.commit('ui/REMOVE_LOADING');
      }
    },
    /* eslint-enable max-statements */
    recoverPassword() {
      this.$store.dispatch('auth/recoverPassword', this.email)
        .then(() => {
          this.$data.success = true;
          this.$ga.event({
            eventCategory: 'RecoverPasswordPopup',
            eventAction: 'SendRecoverPassword',
            eventLabel: 'RecoverPassword',
          });
        })
        .catch();
    },
    toggleForm() {
      this.loginForm = !this.loginForm;
      this.$ga.event({
        eventCategory: 'LoginPopUp',
        eventAction: this.loginForm ? 'RecoverPasswordToggle' : 'LoginToggle',
        eventLabel: 'Login',
      });
    },
    toggleForPass() {
      this.mdlPass = !this.mdlPass;
    },
    closeDialog() {
      this.$store.dispatch('ui/hideDialog', 'login');
    },
    ...mapActions('options', ['setChosenSchool']),
    ...mapMutations('ui', {
      addLoading: 'ADD_LOADING',
      removeLoading: 'REMOVE_LOADING',
    }),
  },
};
</script>

<style lang="scss" scoped>
  @import "../../../styles/app/variables";
  .google {
    margin-top: 10px;

    &__btn {
      height: 50px;
      width: 100%;
      margin: auto;
      border-radius: 24px;
      background-color: $navigation-background;
      // border: 1px solid $main-text-color;
      border: 1px solid $primary-color;
      cursor: pointer;
    }

    &__logo {
      padding-top: 5px;
      padding-left: 3%;
      width: 10%;
      float: left;
      padding-bottom: 5px;
      height: 50px;
    }

    &__text-container {
      width: 90%;
      float: left;
    }

    &__text-container-full {
      width: 100%;
    }

    &__login-text {
      text-align: center;
      width: 90%;
      font-size:18px;
      float:left;
      line-height:48px;
      color: $primary-color;
      // font-family: "Roboto",arial,sans-serif;
      margin-left: auto;
    }

    &__login-text-full {
      text-align: center;
      width: 100%;
      font-size:18px;
      float:left;
      line-height:48px;
      color: $primary-color;
      // font-family: "Roboto",arial,sans-serif;
      margin-left: auto;
    }
  }

  .password-recover {
    display: block;
    width: 100%;
    text-align: center;
    left: 0;
    // position: absolute;
    margin-top: 10px;
    margin-bottom: 25px;
    text-align: left;
    a {
      color: $primary-color !important;
    }
  }

  .free-trial {
    display: block;
    width: 100%;
    text-align: center;
    left: 0;
    position: absolute;
    margin-top: 45px;
    a {
      font-weight: 300;
      color: $main-text-color;
    }
  }

  .conten-frm {
    width: 100%;
  }
  .title-sesion {
    font-size: 24px;
    color:#555;
    margin-bottom: 20px;
    text-align: left;
  }
  .login-dialog__form button {
    margin: 10px 0px !important;
  }
  .dividerLogin {
    width: 40%;
    border-top: 1px solid #ccc;
    display: inline-block;
  }
  .contenO {
    display: inline-block;
    text-align: center;
    width: 30px;
  }
  .titleh2 {
    text-align: left !important;
    margin-bottom: 0px !important;
  }
  .conten-text {
    text-align: justify;
    white-space: normal;
  }
  .mdl-conten-body {
    height: calc(100% - 5px);
    overflow: auto;
    padding-right: 10px;
    width: 104%;
  }
</style>
