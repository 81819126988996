<template>
  <paywall
    :permitted="isLoggedIn && hasAttendancePermission"
    :free="isFreeUser"
    restricted
    transparent>

    <div id="downTable" class="results-colormap">
      <div
        v-if="results.length > 0"
        class="conten-filters"
      >
        <div class="ttl-panel">Panel Asistencia por mes</div>
        <div class="sub-filters">
          <template v-if="!keyUndefined">
            <div
              v-if="results[0].closHeaders.length > 0"
              class="conten-treeselects"
            >
              <i class="icon-drw material-icons hide-print">
                keyboard_arrow_down
              </i>
              <label
                v-if="valueT.length===0"
                class="label-treeselect">Seleccionar...
              </label>
              <treeselect
                v-model="valueT"
                :multiple="true"
                :options="optionsT"
                placeholder=""
                @select="selectDiaSemana($event)"
                @input="inputDiaSemanaEvent($event)"/>
            </div>
          </template>
          <div class="conten-picker">
            <template v-if="loadSelect">
              <rdr-picker
                v-model="chosenStage"
                :options="optionMeses"
                :loading="0"
                label-property="name"
                value-property="key"
                picker-style="round"
                @input="getChangeMonth"/>
            </template>
          </div>
        </div>
      </div>
      <template v-if="loading">
        <div>
          <transition name="fade">
            <loading-overlay/>
          </transition>
        </div>
      </template>
      <template v-else>
        <div v-if="vertodo == 0">
          <transition name="fade">
            <loading-overlay/>
          </transition>
        </div>
        <div
          v-if="vertodo == 1"
          class="results-colormap__color-map">
          <color-map-month
            :loading="loading"
            :results="arrayOrigen"
            :mes-selected="mesName"
            results-row-key="group_id"
          />
        </div>
        <div
          v-if="vertodo == 2"
          class="results-colormap__color-map">
          <color-map-month
            :loading="loading"
            :results="resultsClon"
            :mes-selected="mesName"
            results-row-key="group_id"
          />
        </div>
        <div
          v-if="vertodo == 5"
          class="results-colormap__color-map">
          <color-map-month
            :results="[]"
            :loading="false"
            :mes-selected="mesName"
            :error-empty="true"
            results-row-key="group_id"
          />
        </div>
      </template>

      <template v-if="!keyUndefined && vertodo != 5">
        <color-map-footer
          v-if="results[0].closHeaders.length > 0"
          :footer-bottom-paragraphs="footerParagraphs"
          text="La gama de colores representa el porcentaje de asistencia de la siguiente forma:"
          high="100%"
          average="90%"
          low="80% o menos"
        />
      </template>
    </div>
  </paywall>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import ColorMapMonth from '../../../components/color-map-month.vue';
import ColorMapFooter from '../../../components/results/color-map-footer.vue';
import AuthMixin from '../../../mixins/auth';
import PermissionMixin from '../../../mixins/permission';
import ResultsMixin from '../../../mixins/results';
import MockDataMixin from '../../../mixins/mock-data';
import RdrPicker from '../../../components/utils/picker';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  name: 'AttendanceBySchoolMonthView',
  components: {
    ColorMapFooter,
    ColorMapMonth,
    RdrPicker,
    Treeselect,
  },
  mixins: [AuthMixin, MockDataMixin, PermissionMixin, ResultsMixin],
  data() {
    return {
      footerParagraphs: [
        'Los cálculos de inasistencia del año en curso se obtienen a partir de la información del sistema SIGE de cada establecimiento.',
        'Los colores son referenciales para cada concepto y no necesariamente representan las metas internas de cada proyecto educativo.',
      ],
      chosenStageKey: 1,
      loadSelect: true,
      mesName: 'Ene',
      valueMes: {
        'key': 1,
        'name': 'Enero',
      },
      optionMeses: [
        {
          'key': 1,
          'name': 'Enero',
        },
        {
          'key': 2,
          'name': 'Febrero',
        },
        {
          'key': 3,
          'name': 'Marzo',
        },
        {
          'key': 4,
          'name': 'Abril',
        },
        {
          'key': 5,
          'name': 'Mayo',
        },
        {
          'key': 6,
          'name': 'Junio',
        },
        {
          'key': 7,
          'name': 'Julio',
        },
        {
          'key': 8,
          'name': 'Agosto',
        },
        {
          'key': 9,
          'name': 'Septiembre',
        },
        {
          'key': 10,
          'name': 'Octubre',
        },
        {
          'key': 11,
          'name': 'Noviembre',
        },
        {
          'key': 12,
          'name': 'Diciembre',
        },
      ],

      valueT: ['todo'],
      optionsT: [
        {
          id: 'todo',
          label: 'Toda la semana',
        },
        {
          id: 'lunes',
          label: 'Lunes',
        },
        {
          id: 'martes',
          label: 'Martes',
        },
        {
          id: 'miércoles',
          label: 'Miércoles',
        },
        {
          id: 'jueves',
          label: 'Jueves',
        },
        {
          id: 'viernes',
          label: 'Viernes',
        },
      ],
      firstLoad: true,
      mesSelect: 1,
      valSelected: {},
      firstSelectAction: true,
      arrayOrigen: [],
      resultsClon: [],
      vertodo: 0,
      loadScript: 0,
      keyUndefined: true,
      diaSelect: false,
    };
  },
  computed: {
    ...mapState('results', ['loading']),
    ...mapGetters('results', {
      evaluations: 'resultsEvaluations',
      resultsGetter: 'results',
    }),
    ...mapGetters('options', ['groups']),

    results() {
      if (this.resultsGetter === 'ERROR-500') {
        this.vertodo = 5;
        this.resultsClon = [];
        return this.resultsClon;
      }
      if (!this.diaSelect) {
        this.arrayOrigen = [];
        this.arrayOrigen = JSON.parse(JSON.stringify(this.resultsGetter));
        if (this.arrayOrigen[0].closHeaders === undefined) { this.keyUndefined = true; }
        else { this.keyUndefined = false; }
        if (this.loading === 0) { this.inputDiaSemana(this.valueT); }
      }
      return this.resultsGetter;
    },
    chosenStage: {
      get() {
        let keySelect = parseInt(this.chosenStageKey);
        if (this.firstLoad) {
          const fecha = new Date();
          keySelect = fecha.getMonth() + 1;
          this.mesSelect = keySelect;
          this.chosenStageKey = keySelect;
          this.firstLoad = false;
          this.setViewParams();
        } else {
          this.mesSelect = keySelect;
          this.setViewParams();
        }
        const objMes = this.optionMeses.find(stage => stage.key === keySelect);
        this.mesName = objMes.name.substring(0, 3);
        return objMes;
      },
      set(value) {
        this.chosenStageKey = value;
      },
    },
  },
  methods: {
    ...mapActions('options', ['setReportParams', 'setChosenMonth']),
    setViewParams() {
      this.loadScript = 0;
      this.loadSelect = false;
      const fecha = new Date();
      this.loadSelect = true;
      this.setChosenMonth(this.mesSelect);
      this.setReportParams({ 'school_ids': true, 'years': true, 'months': true });
      this.setReportKey('attendance-aggregate-by-month-day');
    },
    selectDiaSemana(e) {
      this.diaSelect = true;
      this.valSelected = e;
    },
    inputDiaSemanaEvent(elem) {
      this.vertodo = 0;
      this.loadScript = 0;
      setTimeout(() => {
        this.inputDiaSemana(elem);
      }, 500);
    },
    inputDiaSemana(e) {
      if (parseInt(this.loadScript) < 7) {
        this.resultsClon = JSON.parse(JSON.stringify(this.arrayOrigen));
        const objSasbado = {
          id: 'sábado',
          label: 'Sábado',
        };
        let continiuFunc = true;
        if (e.length > 1 && e[0] === 'todo') {
          e.shift();
          this.vertodo = 1;
        }
        if (this.arrayOrigen[0].closHeaders !== undefined) {
          const self = this;
          setTimeout(() => {
            if (self.arrayOrigen[0].filtro_sabado && self.optionsT.length === 6) self.optionsT.push(objSasbado);
            if (!self.arrayOrigen[0].filtro_sabado && self.optionsT.length === 7) delete(self.optionsT[6]);
            let objClon = [];
            if (self.valSelected.id === 'todo' || self.valueT.length === 0) {
              self.valueT = ['todo'];
              self.reloadTables(1);
              continiuFunc = false;
            }
            if (continiuFunc && self.valueT[0] !== 'todo') {
              self.resultsClon[0] = [];
              objClon = JSON.parse(JSON.stringify(self.arrayOrigen[0]));
              objClon.closHeaders = [];
              objClon.totals = [];
              let arrayHeader = [];
              let arrayTotals = [];
              let firstFor = true;
              self.arrayOrigen[0].groups.forEach((grupo, key) => {
                let arrayPorcent = [];
                // const arrayCursos = [];
                objClon.groups[key].present_percentage = [];

                // PARA OBTENER PORCENTAJE DE GRUPO PADRE
                grupo.present_percentage.forEach((val, key2) => {
                  e.forEach((dia) => {
                    const valDiaUpper = val.dia.toUpperCase();
                    const diaUpper = dia.toUpperCase();

                    if (valDiaUpper === diaUpper) {
                      arrayPorcent.push(val);
                      if (firstFor) {
                        arrayHeader.push(self.arrayOrigen[0].closHeaders[key2]);
                        arrayTotals.push(self.arrayOrigen[0].totals[key2]);
                      }
                    }
                  });
                });

                // PARA OBTENER PORCENTAJE DE CURSOS HIJOS
                grupo.cursos.forEach((valCurso, keyCur) => {
                  objClon.groups[key].cursos[keyCur].present_percentage = [];
                  let arrayPorcenCurso = [];
                  valCurso.present_percentage.forEach((valPorcen, keyPorcen) => {
                    e.forEach((dia) => {
                      const valDiaUppers = valPorcen.dia;
                      const diaUppers = dia.toUpperCase();
                      if (valDiaUppers.toUpperCase() === diaUppers) {
                        arrayPorcenCurso.push(valPorcen);
                      }
                    });
                  });
                  objClon.groups[key].cursos[keyCur].present_percentage = JSON.parse(JSON.stringify(arrayPorcenCurso));
                });
                firstFor = false;
                objClon.groups[key].present_percentage = arrayPorcent;
              });
              objClon.closHeaders = arrayHeader;
              objClon.totals = arrayTotals;
              self.resultsClon[0] = JSON.parse(JSON.stringify(objClon));
              self.reloadTables(2);
            }
            self.loadScript += 1;
          }, 500);
        }
      }
      if (this.vertodo !== 1 && parseInt(this.loadScript) > 6) {
        this.vertodo = 2;
      }
    },

    reloadTables(typeTable) {
      this.vertodo = 0;
      const self = this;
      setTimeout(() => {
        self.vertodo = typeTable;
      }, 400);
    },

    getChangeMonth() {
      this.diaSelect = false;
      this.loadScript = 0;
    },
  },
};
</script>

<style>
  .conten-filters {
    margin-bottom: 30px;
    height: 60px;
  }
  .ttl-panel {
    float: left;
    padding-top: 27px;
    font-size: 14px;
    color: #212121;
    font-weight: 700;
  }
  .sub-filters {
    width: 60%;
    float: right;
    text-align: right;
    max-height:45px;
  }
  .conten-treeselects {
    display: inline-block !important;
    width: 300px;
    margin-bottom: -13px;
    margin-right: 15px;
    vertical-align: text-bottom;
    height: 57px;
  }
  .conten-picker {
    display: inline-block;
    width: 180px;
  }
  .vue-treeselect__control {
    border-radius: 19px !important;
    font-size: 19px !important;
    height: 38px !important;
    border: 1px solid #ccc;
    background: transparent !important;
  }
  .vue-treeselect__control:hover {
    border-radius: 19px !important;
    border: 1px solid #177ED3;
  }
  .label-treeselect {
    position: relative;
    float: left;
    top: 28px;
    left: 15px;
  }
  .icon-drw {
    border: 1px solid;
    border-radius: 13px;
    color: #177ED3;
    text-align: center;
    font-size: 22px;
    padding-top: 1px;
    height: 24px;
    position: relative;
    bottom: -23px;
    right: 5px;
    background: #FFF;
  }
  .vue-treeselect {
    margin-top: -12px;
  }
  .vue-treeselect__control-arrow-container {
    opacity: 0 !important;
  }
  .vue-treeselect__menu {
    border-radius: 19px !important;
    border: 1px solid #177ED3 !important;
    margin-top: 0px !important;
  }
</style>
